import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
    state: {

    },
    getters: {
        
    },
    actions: {
        getPipelineExecutions({}, pipeline_name){
            return new Promise((resolve,reject) => {
              axios.post(`${BASE_URL}/release/get/pipeline/execution`,{ pipeline_name }).then((response) => {
                resolve(response.data.data)
              }).catch(error => {
                reject(error)
              })
            })
        },
        startPipeline({}, pipeline_name){
            return new Promise((resolve,reject) => {
              axios.post(`${BASE_URL}/release/start/pipeline`,{ pipeline_name }).then((response) => {
                resolve(response.data.data)
              }).catch(error => {
                reject(error)
              })
            })
        },
    }
}