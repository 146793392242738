import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { SetupCalendar } from 'v-calendar';
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import VueLoading from 'vue-loading-overlay';
import VueExcelEditor from 'vue3-excel-editor'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import DataTable from 'datatables.net-vue3';
import 'vue-loading-overlay/dist/vue-loading.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'v-calendar/dist/style.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';


const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookies)
app.use(VueAxios, axios)
app.use(SetupCalendar, {})
app.use(VueLoading);
app.use(VueExcelEditor)
app.use(VueTelInput);
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('DataTable', DataTable);
app.component('QuillEditor', QuillEditor);



axios.interceptors.request.use(
  config => {
    var token;
      if(app.config.globalProperties.$cookies.get("userAccess") != null){
        token = `Bearer ${app.config.globalProperties.$cookies.get("userAccess")}` 
      }
      if (token && !config.url.includes("https://avishkaar-assets.s3.amazonaws.com") && !config.url.includes("https://api.postalpincode.in/pincode")) {
        config.headers.Authorization = `${token}`;
      }
      return config;
})

axios.interceptors.response.use(
  config => {
    return config
  },
  error => {
    if(error.response.status == 401){
      window.location = 'https://home.avishkaar.cc/'
    }
    return Promise.reject(error);
  }
)

app.mount('#app')
