import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
   //userDetails: [],
  },
  getters: {
    //getUserDetails: (state) => state.userDetails,
  },
  actions: {
    getIrcTeamsForScoring({}, team_level){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/admin/scorePanel/84/${team_level}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getLeagueScoreByteamid({}, team_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/admin/league/points/${team_id}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getZonalScoreByteamid({}, payload){
      console.log(payload)
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/admin/zonal/points/${payload.round_id}/${payload.team_id}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addZonalScore({}, scorePayload){
      return new Promise((resolve,reject) => {
        console.log(scorePayload)
        axios.post(`${BASE_URL}/events/admin/zonal_score`,scorePayload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addLeagueScore({}, scorePayload){
      return new Promise((resolve,reject) => {
        console.log(scorePayload)
        axios.post(`${BASE_URL}/events/admin/league_score`,scorePayload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addEventTask({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/task`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getTask({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/task`).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getAllEvents({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/getAllEvents`).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addEvent({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/adminPanel/create`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    editEvent({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/adminPanel/edit`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getZonalEventTeam({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/zone/getTeam`,payload).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    checkEventCode({}, event_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/checkEventCode/${event_code}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEventByCode({}, event_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/details/${event_code}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTaskById({}, task_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/task/${task_id}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    editEventTask({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.put(`${BASE_URL}/events/task`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    scoreEventZonal({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.put(`${BASE_URL}/events/updatZonalScore`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    qualifyEventZonal({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.put(`${BASE_URL}/events/qualifyTeamZone`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    qualifyEvent({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.put(`${BASE_URL}/events/qualifyTeam`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    publishEventZonal({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/zonePublishResult`,payload).then(response => {
          resolve("success")
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateEventActions({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.put(`${BASE_URL}/events/updateActions`, payload);
          if (urlResponse.status="success") {
            resolve("updated successfully!");
          } else {
            throw('updateActions api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    },
    getTeamDetails({}, pass_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/admin/getTeamByPassCode/${pass_code}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    editEventTeam({}, payload){
      return new Promise((resolve,reject) => {
        console.log(payload)
        axios.post(`${BASE_URL}/events/admin/editTeam`,payload).then(response => {
          console.log(response.data)
          if(response.data.status=='success')
            resolve("success")
          else reject('api error')
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getAllTeam({}, event_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/events/admin/getAllTeams/${event_id}`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    //setUserDetails: (state, userData) => (state.userDetails = userData),
  },
};
