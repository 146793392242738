import { createStore } from 'vuex'
import auth from './modules/auth'
import coupon from './modules/coupon'
import curriculum from './modules/curriculum'
import product from './modules/product'
import program from './modules/program'
import project from './modules/project'
import event from './modules/event'
import partner from './modules/partner'
import blogs from './modules/blogs'
import license from './modules/license'
import release from './modules/release'
import notification from './modules/notification'
import community from './modules/community'
import serviceRequest from './modules/serviceRequest'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    auth,
    curriculum,
    product,
    program,
    project,
    event,
    partner,
    blogs,
    license,
    release,
    notification,
    community,
    serviceRequest,
    coupon
  },
})
