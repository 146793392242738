<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <a href="https://home.avishkaar.cc/"><img src="https://images.avishkaar.cc/misc/home.avishkaar.cc/home/header/Avishkaar-logo.webp" class="mx-auto my-2" alt="Responsive image"></a>
   
    <CSidebarNav>
      <CSidebarBrand>
        <CNavItem href="#/dashboard">
          Dashboard
        </CNavItem>
      </CSidebarBrand>
      <li class="nav-title">Features</li>

      <sideBarNav v-for="(admin,index) in adminRoles" :key="index" :role="admin.role" :inner_role="admin.inner_role" />
      
    </CSidebarNav>

    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import sideBarNav from '@/components/sideBarNav.vue'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    sideBarNav
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      adminRoles: computed(() => store.getters.getRoleDetails)
    }
  },
}
</script>
