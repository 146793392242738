<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CAvatar :src="avatar" size="md" />
      </CHeaderNav>
    </CContainer> 
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import {useStore} from "vuex";
import {computed} from "vue";
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  setup() {
    const store = useStore();
    const avatar = computed(() => store.getters.getUserDetails.profile_img)
    return {
      avatar
    }
  },
}
</script>
