import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "http://localhost:2000";

export default {
    actions: {
        addNotification({}, payload) {
            return new Promise(async (resolve, reject) => {
              try {
                let urlResponse = await axios.post(`${BASE_URL}/notifications/adminPanel`, payload);
                if (urlResponse.data.status=='success') {
                  resolve("success");
                } else {
                  throw('adding notifications failed!');
                }
              } catch(err) {
                  reject(err);
              }
            });
          },
          getAllNotification() {
            return new Promise(async (resolve, reject) => {
              try {
                let urlResponse = await axios.get(`${BASE_URL}/notifications/adminPanel`);
                if (urlResponse.data.status=='success') {
                  resolve(urlResponse.data.data);
                } else {
                  throw('getting notifications failed!');
                }
              } catch(err) {
                  reject(err);
              }
            });
          },
    }
};