import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {

  },
  getters: {
   
  },
  actions: {
    fetchCouponListing({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/coupons/admin/listing`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateShopLive({ }, couponPayload){
        return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/coupons/shop/live`, couponPayload).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error)
          })
        })
    },
    updateDiscountCoupon({ }, couponPayload){
        return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/coupons/update/discount`, couponPayload).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error)
          })
        })
    },
  },
  mutations: {
  
  },
};
