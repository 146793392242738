import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";

export default {
    state: {

    },
    getters: {

    },
    actions: {
      getCommunityDashboardData({}, activityLevel){
        return new Promise((resolve,reject) => {
          axios.get(`${BASE_URL}/activities/community/dashboard/${activityLevel}`).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      getCommunityUserData({}, user_id){
        return new Promise((resolve,reject) => {
          axios.post(`${BASE_URL}/users/account/details`,{ user_id }).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      getUserData({}, phoneOrEmail){
        return new Promise((resolve,reject) => {
          axios.get(`${BASE_URL}/users/${phoneOrEmail}`).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      addPopupDetails({}, payload){
        return new Promise((resolve,reject) => {
          axios.post(`${BASE_URL}/media/create/popup`,payload).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      updatePopupDetails({}, payload){
        return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/media/edit/popup`,payload).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      getPopupListing({}){
        return new Promise((resolve,reject) => {
          axios.get(`${BASE_URL}/media/popup/listing`).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      getPopupDetailById({},popup_id){
        return new Promise((resolve,reject) => {
          axios.get(`${BASE_URL}/media/popup/detail/${popup_id}`).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
      updatePopupPublish({}, payload){
        return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/media/publish/popup`,payload).then((response) => {
            resolve(response.data.data)
          }).catch(error => {
            reject(error.response.data)
          })
        })
      },
    },
    mutations: {

    }
}