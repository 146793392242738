import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "http://localhost:2000";

export default {
  actions: {
    uploadMedia({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.post(`${BASE_URL}/blogs/upload/signedURL`, {name:payload.name});
          if (urlResponse.data.url) {
            await axios.put(urlResponse.data.url, payload, { headers: { 'Content-Type': payload.type } });
            let s3url=urlResponse.data.url.substr(0,urlResponse.data.url.indexOf("?Content-Type="));
            resolve(s3url);
          } else {
            throw('signed url api failed!');
          }
        } catch(err) {
          // let reseee=`https://avishkaar-assets.s3.amazonaws.com/blogs/1_A6kkoOVJVpXPWewg8axc5w.png?Content-Type=application%2Foctet-stream&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=utkarsh.kushwaha%40buildingblocks.in%2F20240222%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240222T084029Z&X-Amz-Expires=60&X-Amz-Signature=87bd1247209c5764dac17fc52f393029035f502382d1258b8439bea9a8d8839c&X-Amz-SignedHeaders=host`;
          // reseee=reseee.substr(0,reseee.indexOf("?Content-Type="));
          // resolve(reseee);
          reject(err);
        }
      });
    },    
    addBlog({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.post(`${BASE_URL}/blogs`, payload);
          if (urlResponse.data.status=='success') {
            resolve("success");
          } else {
            throw('adding blog api failed!');
          }
        } catch(err) {
            reject(err);
        }
      });
    },
    editBlog({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.put(`${BASE_URL}/blogs`, payload);
          if (urlResponse.data.status=='success') {
            resolve("success");
          } else {
            throw('updating blog api failed!');
          }
        } catch(err) {
            reject(err);
        }
      });
    },
    getBlogByUri({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.get(`${BASE_URL}/blogs/getBlogByUri/${payload}`);
          if (urlResponse.data) {
            resolve(urlResponse.data.data);
          } else {
            throw('getBlogByUri api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    },
    getBlogCategory({}) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.get(`${BASE_URL}/blogs/getBlogCategory`);
          if (urlResponse.data) {
            resolve(urlResponse.data.data);
          } else {
            throw('getBlogCategory api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    },
    getAllBlogs({}) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.get(`${BASE_URL}/blogs/getAllBlogAdmin`);
          if (urlResponse.data) {
            resolve(urlResponse.data.data);
          } else {
            throw('getAllBlog api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    },
    updateActions({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.put(`${BASE_URL}/blogs/updateActions`, payload);
          if (urlResponse.status="success") {
            resolve("updated successfully!");
          } else {
            throw('updateActions api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    }
  },
};
