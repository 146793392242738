import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
   adminLicenseListing: [],
   licenseItems: [],
   licenseItemTypes: []
  },
  getters: {
    getAdminLicenseListing: (state) => state.adminLicenseListing,
    getLicenseItems: (state) => state.licenseItems,
    getLicenseItemTypes: (state) => state.licenseItemTypes,
  },
  actions: {
    fetchAdminLicense({ commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`${BASE_URL}/license/admin/list`).then((response) => {
                commit("setAdminLicenseList",response.data.data)
                resolve(response.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createAdminLicense({ }, licensePayload) {
        return new Promise((resolve,reject) => {
            axios.post(`${BASE_URL}/license/create`,licensePayload).then((response) => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },
    fetchLicenseItems({ commit},license_code) {
        return new Promise((resolve,reject) => {
            axios.get(`${BASE_URL}/license/admin/items/${license_code}`).then((response) => {
                
                commit("setLicenseItems",response.data.data)
                if(response.data.data) commit("setLicenseItemsTypes",response.data.data.map(item => item.item_type))
                else commit("setLicenseItemsTypes",[])
                resolve(response.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    addLicenseItem({ }, itemPayload) {
        return new Promise((resolve,reject) => {
            if(itemPayload.is_update){
                axios.put(`${BASE_URL}/license/update/items`,itemPayload).then((response) => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            }else{
                axios.post(`${BASE_URL}/license/add/items`,itemPayload).then((response) => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            }
        })
    },
    removeLicenseItem({ }, licensePayload) {
        return new Promise((resolve,reject) => {
            axios.put(`${BASE_URL}/license/remove/items`,licensePayload).then((response) => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },
  },
  mutations: {
    setAdminLicenseList: (state, licenseData) => (state.adminLicenseListing = licenseData),
    setLicenseItems: (state, licenseItems) => (state.licenseItems = licenseItems),
    setLicenseItemsTypes: (state, licenseItemTypes) => (state.licenseItemTypes = licenseItemTypes),
  },
};
